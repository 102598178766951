import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'gatsby';
import styles from './responsive-slider.module.scss';

export default ({slides}) => {

  const renderSlides = () => {
    return slides.map(
      (
        { title, description, ctaButtonText, ctaButtonLink, iconClass },
        index
      ) => (
        <div className={styles.slideOuter} key={index}>
          <div className={`${styles.slide}`}>
            <div className={`${styles.slideIcon} ${iconClass}`}></div>
            <h3 className="title is-5 has-text-white has-text-centered">
              {title}
            </h3>
            <p className="has-text-centered content is-size-7">{description}</p>
            {ctaButtonText ? (
              <Link
                className="button is-primary is-outlined is-inverted"
                to={ctaButtonLink}
              >
                {ctaButtonText}
              </Link>
            ) : null}
          </div>
        </div>
      )
    );
  };

  return (
    /*
      Show the slider on mobile and a 3 column grid on tablet and above
    */
    <>
      <Slider
        dots
        infinite
        slidesToShow={1}
        slidesToScroll={1}
        className={styles.slider}
      >
        {renderSlides()}
      </Slider>
      <div className="columns">
        <div className="column is-12 is-10-desktop is-offset-1-desktop">
          <div className={styles.slideGrid}>{renderSlides()}</div>
        </div>
      </div>
    </>
  );
};
